<template>
  <section>
    <div
      v-if="isCancel"
      class="table">
      <div class="th">
        <span>Notes</span>
      </div>
      <div
        class="tr">
        <!--      <span v-html="remark"></span>-->
        <p v-html="notes"></p>
      </div>
    </div>
    <div class="table">
      <template v-if="isRenew">
        <div class="th" >
          <span>Remarks (Display on Renewal Request)</span>
        </div>
        <div
          class="tr">
          <!--      <span v-html="remark"></span>-->
          <p v-html="renew_remark"></p>
        </div>
      </template>
      <div class="th">
        <span>Remarks (Display on  Receipt)</span>
      </div>
      <div
        class="tr">
        <!--      <span v-html="remark"></span>-->
        <p v-html="remark"></p>
      </div>

    </div>
  </section>

</template>

<script>

export default {
  name: 'remarkInfo',
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    remark(){
      let {remark,renew_remark} = this.form
      return remark?remark.replace(/\n/g, '<br/>'):'-'
    },
    renew_remark(){
      let {remark,renew_remark} = this.form
      return renew_remark?renew_remark.replace(/\n/g, '<br/>'):'-'
    },
    notes(){
      let {cancellation_notes} = this.form
      return cancellation_notes?cancellation_notes.replace(/\n/g, '<br/>'):'-'
    },
    isCancel(){
      return this.form.policy_type===204
    },
    isRenew(){
      return this.form.policy_type===201
    },
  }
}
</script>

<style scoped>

</style>
