<template>
  <section>
    <policy-detail-info :form="formData"></policy-detail-info>
    <policy-holder-detail-info :form="formData"></policy-holder-detail-info>
    <div class="table">
      <div class="th">
        <span>Insured Detail</span>
      </div>
      <div
        class="tr">
        <span>Product Detail</span>
        <span>{{insured_detail.product_detail}}</span>
      </div>
    </div>
    <remark-info :form="formData"></remark-info>
    <signature
      :form="formData"></signature>
    <document-info :form="formData"></document-info>
    <referral-info :form="formData.referral"></referral-info>
  </section>
</template>

<script>
import policyDetailInfo from '@/views/components/info/policyDetailInfo'
import travelerInfo from '@/views/components/info/travelerInfo'
import policyHolderDetailInfo from '@/views/components/info/policyHolderDetailInfo'
import remarkInfo from '@/views/components/info/remarkInfo'
import documentInfo from '@/views/components/info/documentInfo'
import referralInfo from '@/views/components/info/referralInfo'
import signature from '@/views/components/info/signature'

export default {
  name: 'otherInfo',
  components:{
    policyDetailInfo,
    policyHolderDetailInfo,
    remarkInfo,
    documentInfo,
    referralInfo,
    signature
  },
  props:{
    formData:{
      type:Object,
      default:()=>({})
    }
  },
  data(){
    return{
      // formData:{
      //   payment:{}
      // },
    }
  },
  computed:{
    coverage(){
      return this.formData.coverage||{}
    },
    insured_detail(){
      return this.formData.insured_detail||{}
    }
  },
}
</script>
