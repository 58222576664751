<template>
  <table
    class="table-list"
    :class="{'table-list__premium':isPremium}">
    <tr class="th">
      <th >Document</th>
      <th
        v-if="isPremium"
      >Created Date</th>
    </tr>
    <tr v-if="isRenew">
      <td>
        <span>
          Renewal Request
          <div
            v-if="isPremium"
            class="block card-doc">
            <p
              v-if="loadingDone('quotation_pdf_g_path')"
              class="theme-second-blue hand"
              @click="openFile(quotation_document_gross)">{{quotation_document_gross.client_name||'-'}}</p>
            <p
              v-else
              class="text-center fs-14 blue icon-loading-size">
              <i class="el-icon-loading"></i>
              <span class="update-text theme-second-blue">Updating</span>
            </p>
          </div>
        </span>
        <template v-if="!isPremium">
          <span
            v-if="loadingDone('quotation_pdf_g_path')"
            class="theme-second-blue hand"
            @click="openFile(quotation_document_gross)">{{quotation_document_gross.client_name||'-'}}</span>
          <span
            v-else
            class="text-center blue icon-loading-size">
            <i class="el-icon-loading"></i>
            <span class="update-text theme-second-blue">Updating</span>
          </span>
        </template>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in quotation_document_gross.history"
          :key="'quotation_document_gross'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr v-if="false">
      <td>
        <span>Quotation <span class="litte-span">Total Payable</span>
          <div
            v-if="isPremium"
            class="card-doc">
            <p
              v-if="loadingDone('quotation_pdf_path')"
              class="theme-second-blue hand"
              @click="openFile(quotation_document)">{{quotation_document.client_name||'-'}}</p>
            <p
              v-else
              class="text-center blue icon-loading-size">
              <i class="el-icon-loading"></i>
              <span class="update-text theme-second-blue">Updating</span>
            </p>
          </div>
        </span>
        <template v-if="!isPremium">
          <span
            v-if="loadingDone('quotation_pdf_path')"
            class="theme-second-blue hand"
            @click="openFile(quotation_document)">{{quotation_document.client_name||'-'}}</span>
          <span
            v-else
            class="text-center blue icon-loading-size">
            <i class="el-icon-loading"></i>
            <span class="update-text theme-second-blue">Updating</span>
          </span>
        </template>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in quotation_document.history"
          :key="'quotation_document'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr v-if="!(isRenew&&quotation_document_gross)">
      <td>
        <span>Invoice <span class="litte-span">Gross Premium</span>
          <div
            v-if="isPremium"
            class="card-doc">
            <p
              v-if="loadingDone('inv_pdf_g_path')"
              class="theme-second-blue hand"
              @click="openFile(invoice_document_gross)">{{invoice_document_gross.client_name||'-'}}</p>
            <p
              v-else
              class="text-center blue icon-loading-size">
              <i class="el-icon-loading"></i>
              <span class="update-text theme-second-blue">Updating</span>
            </p>
          </div>
        </span>
        <template v-if="!isPremium">
          <span
            v-if="loadingDone('inv_pdf_g_path')"
            class="theme-second-blue hand"
            @click="openFile(invoice_document_gross)">{{invoice_document_gross.client_name||'-'}}</span>
          <span
            v-else
            class="text-center blue icon-loading-size">
            <i class="el-icon-loading"></i>
            <span class="update-text theme-second-blue">Updating</span>
          </span>
        </template>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in invoice_document_gross.history"
          :key="'quotation_document_gross'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr v-if="false">
      <td>
        <span>Invoice  <span class="litte-span">Total Payable</span>
          <div
            v-if="isPremium"
            class="card-doc">
            <p
              v-if="loadingDone('inv_pdf_path')"
              class="theme-second-blue hand"
              @click="openFile(invoice_document)">{{invoice_document.client_name||'-'}}</p>
            <p
              v-else
              class="text-center blue icon-loading-size">
              <i class="el-icon-loading"></i>
              <span class="update-text theme-second-blue">Updating</span>
            </p>
          </div>
        </span>
        <template v-if="!isPremium">
          <span
            v-if="loadingDone('inv_pdf_path')"
            class="theme-second-blue hand"
            @click="openFile(invoice_document)">{{invoice_document.client_name||'-'}}</span>
          <span
            v-else
            class="text-center blue icon-loading-size">
            <i class="el-icon-loading"></i>
            <span class="update-text theme-second-blue">Updating</span>
          </span>
        </template>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in invoice_document.history"
          :key="'invoice_document'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr >
      <td>
        <span>Receipt  <span class="litte-span">Gross Premium</span>
          <div
            v-if="isPremium"
            class="card-doc">
            <p
              v-if="loadingDone('rec_pdf_g_path')"
              class="theme-second-blue hand"
              @click="openFile(receipt_document_gross)">{{receipt_document_gross.client_name||'-'}}</p>
            <p
              v-else
              class="text-center blue icon-loading-size">
              <i class="el-icon-loading"></i>
              <span class="update-text theme-second-blue">Updating</span>
            </p>
          </div>
        </span>
        <template v-if="!isPremium">
          <span
            v-if="loadingDone('rec_pdf_g_path')"
            class="theme-second-blue hand"
            @click="openFile(receipt_document_gross)">{{receipt_document_gross.client_name||'-'}}</span>
          <span
            v-else
            class="text-center blue icon-loading-size">
            <i class="el-icon-loading"></i>
            <span class="update-text theme-second-blue">Updating</span>
          </span>
        </template>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in receipt_document_gross.history"
          :key="'receipt_document'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr v-if="false">
      <td>
        <span>Receipt  <span class="litte-span">Total Payable</span>
          <div
            v-if="isPremium"
            class="card-doc">
            <p
              v-if="loadingDone('rec_pdf_path')"
              class="theme-second-blue hand"
              @click="openFile(receipt_document)">{{receipt_document.client_name||'-'}}</p>
            <p
              v-else
              class="text-center blue icon-loading-size">
              <i class="el-icon-loading"></i>
              <span class="update-text theme-second-blue">Updating</span>
            </p>
          </div>
        </span>
        <template v-if="!isPremium">
          <span
            v-if="loadingDone('rec_pdf_path')"
            class="theme-second-blue hand"
            @click="openFile(receipt_document)">{{receipt_document.client_name||'-'}}</span>
          <span
            v-else
            class="text-center blue icon-loading-size">
            <i class="el-icon-loading"></i>
            <span class="update-text theme-second-blue">Updating</span>
          </span>
        </template>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in receipt_document.history"
          :key="'receipt_document'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr
      v-if="!isCompany"
      class="alt">
      <td>
        <span>HKID Card / Passport
          <span>
            <file-container-card
              :edit="true"
              :can-del="false"
              :fileData="hkid_file"
            />
          </span>
        </span>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in hkid_file.history"
          :key="'hkid_file'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <template v-else>
      <tr
        class="alt">
        <td>
          <span>CI
            <span>
              <file-container-card
                :edit="true"
                :can-del="false"
                :fileData="ci_file"
              />
            </span>
          </span>
        </td>
        <td v-if="isPremium">
          <span
            v-for="(item,index) in ci_file.history"
            :key="'ci_file'+index"
            class="block">
            <span>{{item.date}}</span>
            <span>{{item.created_by}}</span>
          </span>
        </td>
      </tr>
      <tr
        class="alt">
        <td>
          <span>BR
            <span>
              <file-container-card
                :edit="true"
                :can-del="false"
                :fileData="br_file"
              />
            </span>
          </span>
        </td>
        <td v-if="isPremium">
          <span
            v-for="(item,index) in br_file.history"
            :key="'br_file'+index"
            class="block">
            <span>{{item.date}}</span>
            <span>{{item.created_by}}</span>
          </span>
        </td>
      </tr>
    </template>
    <tr>
      <td>
        <span>Vehicle Registration Document
          <span>
            <file-container-card
              :edit="true"
              :can-del="false"
              :fileData="vrd_file"
            />
          </span>
        </span>
        <!--        <span-->
        <!--          v-if="!isPremium"-->
        <!--          class="theme-second-blue hand"-->
        <!--          @click="openFile(vrd_file)">{{vrd_file.client_name||'-'}}</span>-->
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in vrd_file.history"
          :key="'vrd_file'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <!--    <tr class="alt">-->
    <!--      <td>-->
    <!--        <span>Driving Licence</span>-->
    <!--        <span-->
    <!--          class="theme-second-blue hand"-->
    <!--          @click="openFile(driving_licence_file)">{{driving_licence_file.client_name||'-'}}</span>-->

    <!--      </td>-->
    <!--      <td v-if="isPremium">-->
    <!--        <span-->
    <!--          v-for="(item,index) in driving_licence_file.history"-->
    <!--          :key="'ncb_file'+index"-->
    <!--          class="block">-->
    <!--          <span>{{item.date}}</span>-->
    <!--          <span>{{item.created_by}}</span>-->
    <!--        </span>-->
    <!--      </td>-->
    <!--    </tr>-->
    <tr
      v-if="form.ncb_file"
      class="alt">
      <td>
        <span>NCB proof (e.g. Renewal Notice)
          <span>
            <file-container-card
              :edit="true"
              :can-del="false"
              :fileData="ncb_file"
            />
          </span>
        </span>
        <!--        <span-->
        <!--          v-if="!isPremium"-->
        <!--          class="theme-second-blue hand"-->
        <!--          @click="openFile(ncb_file)">{{ncb_file.client_name||'-'}}</span>-->

      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in ncb_file.history"
          :key="'ncb_file'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr
      v-if="form.proof_of_address_file"
      class="alt">
      <td>
        <span>Proof of Address
          <span>
            <file-container-card
              :edit="true"
              :can-del="false"
              :fileData="proof_of_address_file"
            />
          </span>
        </span>
        <!--        <span-->
        <!--          v-if="!isPremium"-->
        <!--          class="theme-second-blue hand"-->
        <!--          @click="openFile(proof_of_address_file)">{{proof_of_address_file.client_name||'-'}}</span>-->

      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in proof_of_address_file.history"
          :key="'ncb_file'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <!--    <tr v-if="isCompany">-->
    <!--      <td>-->
    <!--        <span>Driver HKID Card / Passport</span>-->
    <!--        <span-->
    <!--          class="theme-second-blue hand"-->
    <!--          @click="openFile(hkid_file)">{{hkid_file.client_name||'-'}}</span>-->
    <!--      </td>-->
    <!--      <td v-if="isPremium">-->
    <!--        <span-->
    <!--          v-for="(item,index) in hkid_file.history"-->
    <!--          :key="'hkid_file'+index"-->
    <!--          class="block">-->
    <!--          <span>{{item.date}}</span>-->
    <!--          <span>{{item.created_by}}</span>-->
    <!--        </span>-->
    <!--      </td>-->
    <!--    </tr>-->
    <template v-if="other_documents.data">
      <tr class="th">
        <th >Other Document</th>
        <th
          v-if="isPremium"
        >Created Date</th>
      </tr>
      <tr class="alt">
        <td>
          <!--          <span v-if="!isPremium">-->
          <!--            <div-->
          <!--              v-for="(item,index) in other_documents.data"-->
          <!--              :key="index"-->
          <!--              class="theme-second-blue hand block"-->
          <!--              @click="openFile(item)">-->
          <!--              {{item.name}}-->
          <!--            </div>-->
          <!--          </span>-->
          <span>
            <div
              v-for="(item,index) in other_documents.data"
              :key="index"
              class="theme-second-blue hand"
            >
              <span class="pd-l-0">{{item.name}}</span>
              <file-container-card
                :edit="true"
                :can-del="false"
                :fileData="item"
              />
            </div>
          </span>
        </td>
        <td  v-if="isPremium">
          <span
            v-for="(item,index) in other_documents.history"
            :key="'documents'+index"
            class="block">
            <span>{{item.date}}</span>
            <span>{{item.created_by}}</span>
          </span>
        </td>
      </tr>
    </template>
    <tr>
      <td>
        <span>Full Set Doc. Received?</span>
        <span v-if="!isPremium">{{getOptionName('yesNoOptions',form.documents_received)}}</span>
      </td>
      <td v-if="isPremium">{{getOptionName('yesNoOptions',form.documents_received)}}</td>
    </tr>
  </table>

</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
import {getQuotationStatus} from '@/api/quotation'

export default {
  name: 'documentInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isPremium:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return{
      setInterval: null,
      isLoading:true,
      loadingData:{},
      document:{}
    }
  },
  computed:{
    order_no(){
      let {order_no} = this.$route.query||{}
      return order_no||''
    },
    loadingDone(){
      return (name) =>{
        if(!this.order_no) return true
        return this.loadingData[name]==0
      }
    },
    isCompany(){
      return this.form.client_type==2
    },
    productId(){
      return this.form.product_id
    },
    isEndorsement(){
      return this.form.policy_type==203
    },
    isRenew(){
      return this.form.policy_type==201
    },
    hkid_file(){
      return  this.form.hkid_file||{}
    },
    other_documents(){
      return  this.form.other_documents||{}
    },
    br_file(){
      return  this.form.br_file||{}
    },
    ci_file(){
      return  this.form.ci_file||{}
    },
    proof_of_address_file(){
      return  this.form.proof_of_address_file||{}
    },
    vrd_file(){
      return  this.form.vrd_file||{}
    },
    ncb_file(){
      return  this.form.ncb_file||{}
    },
    driving_licence_file(){
      return  this.form.driving_licence_file||{}
    },
    documentObj(){
      if(this.order_no)
        return  this.document
      else return  this.form
    },
    quotation_document(){
      return  this.documentObj.quotation_document||{}
    },
    quotation_document_gross(){
      return  this.documentObj.quotation_gross_document||{}
    },
    receipt_document(){
      return  this.documentObj.receipt_document||{}
    },
    receipt_document_gross(){
      return  this.documentObj.receipt_gross_document||{}
    },
    invoice_document(){
      return  this.documentObj.invoice_document||{}
    },
    invoice_document_gross(){
      return  this.documentObj.invoice_gross_document||{}
    },
    documentList(){
      let list = {
        10:this.documentOptions,
        11:this.contractorDocumentOptions,
        12:this.travelDocumentOptions,
        13:this.travelDocumentOptions,
        14:this.employeeDocumentOptions,
        15:this.businessDocumentOptions,
        16:this.travelDocumentOptions,
        17:this.conEmployeeDocumentOptions,
        18:this.travelDocumentOptions
      }
      let {class_id,client_type} = this.form||{}
      let result = []
      this.globalOptions.document_type.some(item=>{

        if (item.class_id == class_id) {
          item.data.some(client=>{
            if(client.client_type==client_type)
              result = client.items
          })
        }
      })
      return result
      return list[this.productId]
    },
  },
  mounted() {
    if(this.order_no)
    {
      this.network().getQuotationStatus()
      this.setInterval = setInterval(() => {
        this.network().getQuotationStatus()
      }, 1000)
    }
  },
  beforeDestroy() {
    clearInterval(this.setInterval)
  },
  methods:{
    openFile(data) {
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    updateStatus(){
      if(this.order_no)
      {
        this.network().getQuotationStatus()
        this.setInterval = setInterval(() => {
          this.network().getQuotationStatus()
        }, 1500)
      }
    },
    network(){
      return{
        getQuotationStatus:async (params) =>{
          let {order_no} = this.$route.query||{}
          if(!order_no) return
          let {data} = await getQuotationStatus({hideLoading: true,order_no}).catch(err=>{
            if(err){
              clearInterval(this.setInterval)
            }
          })
          this.loadingData = data
          this.$emit('update:proposal_path',data.proposal_path)
          this.$emit('update:loadingData',data)
          // this.$emit('update:proposal_path',1)
          this.document = data.documents||{}
          let documentObj = JSON.parse(JSON.stringify(data))
          delete documentObj.documents
          let isComplete = Object.values(documentObj).every(item=>item==0)
          if(isComplete){
            this.isLoading = false
            clearInterval(this.setInterval)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
    .table-list{
      tr{
        vertical-align: text-top;
      }
    }
    .theme-second-blue{
      margin-bottom: 8px;
    }
    .litte-span{
      font-weight: 400!important;
      font-size: 12px !important;
      line-height: 150%;
      color: #2D3748 !important;
      padding: 0;
    }
    .icon-loading-size{
      height: auto!important;
      justify-content:flex-start!important;
      font-size: 14px!important;
      line-height: 14px;
    }
    .update-text{
      //font-size: 12px!important;
      margin-bottom: 0px;
      margin-left: 4px;
      color: $gray;
      padding: 0;
      text-align: left;
    }
    .card-doc{
      span{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
      p{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        padding-top: 10px;
      }
    }
    .pd-l-0{
      padding-left: 0;
    }
</style>
