import { render, staticRenderFns } from "./policyDetailInfo.vue?vue&type=template&id=1c7d89f4&scoped=true&"
import script from "./policyDetailInfo.vue?vue&type=script&lang=js&"
export * from "./policyDetailInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c7d89f4",
  null
  
)

export default component.exports