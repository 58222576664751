<template>
  <div>
    <!--    <div      class="table">-->
    <!--      <div class="th">-->
    <!--        <span>Driver Details</span>-->
    <!--      </div>-->
    <!--      &lt;!&ndash;      <div&ndash;&gt;-->
    <!--      &lt;!&ndash;        class="tr">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <span>Are all the driver(s) has not been involved in a motor accident in the past 3 years?</span>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <span v-if="first_drivers.has_accident">{{getOptionName('accidentsOption', first_drivers.has_accident)}}&ndash;&gt;-->
    <!--      &lt;!&ndash;        </span>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <span v-else></span>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <div&ndash;&gt;-->
    <!--      &lt;!&ndash;        v-if=" first_drivers.has_accident==2"&ndash;&gt;-->
    <!--      &lt;!&ndash;        class="tr">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <span>Number of accident in last 3 years</span>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <span v-if="first_drivers.accidents">{{getOptionName('globalOptions.accidents',first_drivers.accidents)}}&ndash;&gt;-->
    <!--      &lt;!&ndash;        </span>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <span v-else></span>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <div&ndash;&gt;-->
    <!--      &lt;!&ndash;        class="tr">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <span>Are all the driver(s) has not been deducted driving offence points in the past 3 years?</span>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <span >{{getOptionName('accidentsOption',first_drivers.is_deducted_point)}}&ndash;&gt;-->
    <!--      &lt;!&ndash;        </span>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <div&ndash;&gt;-->
    <!--      &lt;!&ndash;        v-if="first_drivers.is_deducted_point==2"&ndash;&gt;-->
    <!--      &lt;!&ndash;        class="tr">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <span>Number of driving offence point in last 3 years</span>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <span v-if="first_drivers.deducted_points">{{getOptionName('globalOptions.deducted_points', first_drivers.deducted_points)}}&ndash;&gt;-->
    <!--      &lt;!&ndash;        </span>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </div>&ndash;&gt;-->

    <!--    </div>-->
    <div
      v-for="(item,index) in drivers"
      :key="index"
      class="table"
    >
      <div class="th">
        <span>Driver {{index+1}}</span>
      </div>
      <div
        class="tr">
        <span>Relationship with Proposer</span>
        <span>{{ getOptionName('relationshipOptions', item.relationship_id)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Title</span>
        <span>{{getOptionName('titleOption',item.title)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Name</span>
        <span>{{item.name}}
        </span>
      </div>
      <div
        class="tr">
        <span>Date of Birth</span>
        <span>{{showHkDate(item.birth)}}
        </span>
      </div>
      <div
        class="tr">
        <span>{{ getOptionName('personalIdTypOptions',item.id_type) }}</span>
        <span>{{item.id_no}}
        </span>
      </div>
      <div
        class="tr">
        <span>Occupation</span>
        <span>{{getOptionName('globalOptions.occ', item.occ_id)}} <span v-if="item.occ_id==70">{{item.occ}}</span>
        </span>
      </div>
      <div
        v-if="form.class_id==1003"
        class="tr">
        <span>Type of Motorcycle Driving licence</span>
        <span>{{getOptionName('globalOptions.driving_licence', item.driving_licence_id)}}
        </span>
      </div>
      <div
        v-else
        class="tr">
        <span>Driving Experience</span>
        <span>{{getOptionName('globalOptions.driving_experience', item.driver_experience_id)}}
        </span>
      </div>
      <div
        v-if="form.class_id==1003&&item.driving_licence_id==3"
        class="tr">
        <span>Year of driving Experience</span>
        <span>{{getDriverExp(item.driver_experience_id)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Number of driving offence point in last 3 years (Excludes traffic accidents)</span>
        <span>{{getOptionName('globalOptions.deducted_points', item.deducted_points)}}
        </span>
      </div>
      <div
        v-if="item.deducted_points!=0"
        class="tr">
        <span>Reason</span>
        <span>
          <p
            v-for="(dec_item,acc_index) in item.deducted_point_reason_id"
            :key="'dec_'+acc_index">{{getOptionName('globalOptions.deducted_point_reason',dec_item)}} <span v-if="dec_item==4">: {{item.deducted_point_reason}}</span></p>
        </span>
      </div>
      <div
        class="tr">
        <span>Have any been involved in any car accident or motor claim in the past 3 years?</span>
        <span>
          <span class="block">{{getOptionName('yesNo3Options', item.has_accident)}}</span>
          <span v-if="item.has_accident==2">
            <p class="fw-700">Reason:</p>
            <p
              v-for="(acc_item,acc_index) in item.accident_reason_id"
              :key="'acc_'+acc_index">{{getOptionName('globalOptions.accident_reason',acc_item)}} <span v-if="acc_item==4">: {{item.accident_reason}}</span></p>
          </span>
        </span>
      </div>
      <div
        class="tr">
        <span>Have any been declined motor insurance, had a motor insurance policy cancelled?</span>
        <span>
          <span class="block">{{getOptionName('yesNo2Options', item.is_declined)}}</span>
          <span v-if="item.is_declined==1">{{item.declined_reason}}</span>
        </span>
      </div>
      <div
        class="tr">
        <span>Have any suffered from any physical or mental infirmity that may affect his/her ability to drive?</span>
        <span>
          <span class="block">{{getOptionName('yesNo2Options', item.is_no_driving_ability)}}</span>
          <span v-if="item.is_no_driving_ability==1">{{item.no_driving_ability_reason}}</span>
        </span>
      </div>
      <div
        class="tr">
        <span>Have been prosecuted by police, Dangerous Driving or driving licence suspended or disqualified by court ?</span>
        <span>
          <span class="block">{{getOptionName('yesNo2Options', item.is_dangerous_driving)}}</span>
          <span v-if="item.is_dangerous_driving==1">{{item.dangerous_driving_reason}}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MixinOption from '@/mixins/options'

export default {
  name: 'diverInfo',
  mixins: [MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    dirverExpList(){
      let class_id = this.form.class_id
      let {driving_experience} = this.globalOptions||{}
      let arr = driving_experience.filter(item=>item.class_id==class_id)
      return arr.length>0?arr[0].item||[]:[]
    },
    drivers(){
      let {drivers=[{}]} = this.form||{}
      return drivers||[{}]
    },
    first_drivers(){
      let arr = this.drivers||[]
      return this.drivers.length>0?arr[0]:{}
    }
  },
  methods:{
    getDriverExp(id){
      let arr = this.dirverExpList.filter(item=>item.id==id)||[]
      return arr.length>0?arr[0].name||'':''
    }
  }
}
</script>

<style scoped>

</style>
