<template>
  <section>
    <table
      v-for="(item,index) in driver"
      :key="index"
      class="table-list"
      :class="{'table-list__premium':isPremium}">
      <tr class="th">
        <th >Driver {{index+1}}</th>
        <!--        <th-->
        <!--          v-if="false"-->
        <!--        >Created Date</th>-->
      </tr>
      <tr v-if="isOneOfDriver&&index!=0||isCompany||!isOneOfDriver">
        <td>
          <span>HKID
            <span>
              <file-container-card
                :edit="true"
                :can-del="false"
                :fileData="item.id_file"
              />
            </span>
          </span>
          <!--          <span-->
          <!--            v-if="!isPremium"-->
          <!--            class="theme-second-blue hand"-->
          <!--            @click="openFile(item.id_file)">{{item.id_file.client_name||'-'}}</span>-->
        </td>
        <td v-if="false">
          <span
            v-for="(item,index) in item.id_file&&item.id_file.history"
            :key="'quotation_document_gross'+index"
            class="block">
            <span>{{item.date}}</span>
            <span>{{item.created_by}}</span>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span>Driving licence
            <span >
              <file-container-card
                :edit="true"
                :can-del="false"
                :fileData="item.driving_licence_file"
              />
            </span>
          </span>
          <!--          <span-->
          <!--            v-if="!isPremium"-->
          <!--            class="theme-second-blue hand"-->
          <!--            @click="openFile(item.driving_licence_file)">{{item.driving_licence_file.client_name||'-'}}</span>-->
        </td>


        <!--        <td v-if="false">-->
        <!--          <span-->
        <!--            v-for="(item,index) in item.driving_licence_file&&item.driving_licence_file.history"-->
        <!--            :key="'quotation_document_gross'+index"-->
        <!--            class="block">-->
        <!--            <span>{{item.date}}</span>-->
        <!--            <span>{{item.created_by}}</span>-->
        <!--          </span>-->
        <!--        </td>-->
      </tr>
      <tr v-if="check70Years(item.birth)">
        <td>
          <span>Medical Examination Certificate
            <span >
              <file-container-card
                :edit="true"
                :can-del="false"
                :fileData="item.mec_file"
              />
            </span>
          </span>
        </td>
      </tr>
      <tr>
        <td  v-if="item.has_accident==2">
          <span>Police Statement
            <template v-if="item.accidents_file&&item.accidents_file.length>0">


              <span
                v-for="(a_item,a_index) in item.accidents_file"
                :key="a_index">
                <file-container-card
                  :edit="true"
                  :can-del="false"
                  :fileData="a_item"
                />
              </span>
            </template>
            <span
              v-else>
              <file-container-card
                :edit="false"
                :can-del="false"
              />
            </span>
          </span>
          <!--          <span-->
          <!--            v-if="!isPremium"-->
          <!--            class="theme-second-blue hand"-->
          <!--            @click="openFile(item.accidents_file)">{{item.accidents_file.client_name||'-'}}</span>-->
        </td>
        <!--        <td v-if="false">-->
        <!--          <span-->
        <!--            v-for="(item,index) in item.accidents_file&&item.accidents_file.history"-->
        <!--            :key="'quotation_document'+index"-->
        <!--            class="block">-->
        <!--            <span>{{item.date}}</span>-->
        <!--            <span>{{item.created_by}}</span>-->
        <!--          </span>-->
        <!--        </td>-->
      </tr>
      <tr v-if="item.is_dangerous_driving==1">
        <td>
          <span>Court Summons
            <span>
              <file-container-card
                :edit="true"
                :can-del="false"
                :fileData="item.dangerous_driving_file"
              />
            </span>
          </span>
          <!--          <span-->
          <!--            v-if="!isPremium"-->
          <!--            class="theme-second-blue hand"-->
          <!--            @click="openFile(item.dangerous_driving_file)">{{item.dangerous_driving_file.client_name||'-'}}</span>-->
        </td>
        <!--        <td  v-if="item.is_dangerous_driving==1">-->
        <!--          <span-->
        <!--            v-for="(item,index) in item.dangerous_driving_file&&item.dangerous_driving_file.history"-->
        <!--            :key="'dangerous_driving_file'+index"-->
        <!--            class="block">-->
        <!--            <span>{{item.date}}</span>-->
        <!--            <span>{{item.created_by}}</span>-->
        <!--          </span>-->
        <!--        </td>-->
      </tr>
    </table>
  </section>

</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
import {getQuotationStatus} from '@/api/quotation'

export default {
  name: 'documentInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isPremium:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return{
      setInterval: null,
      isLoading:true,
      loadingData:{},
      document:{}
    }
  },
  computed:{
    order_no(){
      let {order_no} = this.$route.query||{}
      return order_no||''
    },
    loadingDone(){
      return (name) =>{
        if(!this.order_no) return true
        return this.loadingData[name]==0
      }
    },
    isCompany(){
      return this.form.client_type==2
    },
    productId(){
      return this.form.product_id
    },
    isEndorsement(){
      return this.form.policy_type==203
    },
    isRenew(){
      let {policy_type} = this.form||{}
      return policy_type==201
    },
    isOneOfDriver(){
      let {policy_holder_is_driver} = this.form||{}
      return policy_holder_is_driver==1
    },
    driver(){
      let {drivers} = this.form||{}
      return drivers||[]
    },
  },
  mounted() {
    if(this.order_no)
    {
      this.network().getQuotationStatus()
      this.setInterval = setInterval(() => {
        this.network().getQuotationStatus()
      }, 1000)
    }
  },
  beforeDestroy() {
    clearInterval(this.setInterval)
  },
  methods:{
    openFile(data) {
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    network(){
      return{
        getQuotationStatus:async (params) =>{
          let {order_no} = this.$route.query||{}
          if(!order_no)return
          let {data} = await getQuotationStatus({hideLoading: true,order_no}).catch(err=>{
            if(err){
              clearInterval(this.setInterval)
            }
          })
          this.loadingData = data
          this.$emit('update:proposal_path',data.proposal_path)
          // this.$emit('update:proposal_path',1)
          this.document = data.documents||{}
          let documentObj = JSON.parse(JSON.stringify(data))
          delete documentObj.documents
          let isComplete = Object.values(documentObj).every(item=>item==0)
          if(isComplete){
            this.isLoading = false
            clearInterval(this.setInterval)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.theme-second-blue{
  margin-bottom: 8px;
}
.litte-span{
  font-weight: 400!important;
  font-size: 12px !important;
  line-height: 150%;
  color: #2D3748 !important;
  padding: 0;
}
.icon-loading-size{
  height: auto!important;
  justify-content:flex-start!important;
  font-size: 14px!important;
  line-height: 14px;
}
.update-text{
  //font-size: 12px!important;
  margin-bottom: 0px;
  margin-left: 4px;
  color: $gray;
  padding: 0;
  text-align: left;
}
</style>
