<template>
  <div class="card">
    <template >
      <div
        class="remarks-container"
        :class="{'pd-20':isShow&&remarkList.length>0}">
        <div
          v-for="(item, index) in lastRemarkList"
          :key="index"
          class="remark-box">
          <div class="remark-header">
            <span>{{ item.author }}</span>
            <span>{{ item.created_at }}</span>
          </div>
          <p
            v-if="!isEdit"
            class="cell-wrap"
            v-html="changeLineData(item.remark)"></p>
        </div>
      </div>
    </template>
    <div
      v-if="!isShow"
      class="remark-add"
    >
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"

      >
        <el-form-item prop="remark">
          <v-input
            v-if="remarkList.length<=0||isEdit"
            v-model="formData.remark"
            class="mg-b-15"
            type="textarea"
            :rows="4"
          />
        </el-form-item>
      </el-form>
      <div
        v-if="remarkList.length<=0"
        class="card-button">
        <v-button
          size="small"
          class="button-blue button-theme-padding-13"
          @click="handlerAddRemark">Add Remark</v-button>
      </div>
      <template v-else>
        <div
          class="card-button">
          <v-button
            v-if="!isEdit"
            size="small"
            class="button-blue button-theme-padding-13"
            @click="handlerEdit">Edit</v-button>
          <v-button
            v-else
            size="small"
            class="button-blue button-theme-padding-13"
            @click="handlerAddRemark">Update</v-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {getInternalMarkList,addInternalMark} from'@/api/order'
import MixinRules from '@/mixins/rules'
import MixinOptions from '@/mixins/options'
export default {
  name: 'internetMark',
  mixins: [MixinRules,MixinOptions],
  props:{
    isShow:{
      type:Boolean,
      default:false
    },
    remark:{
      type:Array,
      default:()=>([])
    },
    orderNum:{
      type:String,
      default:''
    }
  },
  data(){
    return{
      remarkList:[],
      isEdit:false,
      formData:{
        remark:''
      }
    }
  },
  computed:{
    orderNo(){
      let {order_no,isDraft} = this.$route.query||{}
      return order_no||this.orderNum
    },
    lastRemarkList(){
      let last = []
      if(this.remarkList.length>0)
        last = this.remarkList.slice(-1)
      return last
    },
    lastRemark(){
      return this.lastRemarkList[0]
    }
  },
  watch:{
    lastRemark:{
      immediate:true,
      handler(val){
        let {remark} =val||{}
        if(remark)
          this.formData.remark = remark||''
      }
    },
    remark:{
      immediate:true,
      handler(val){
        if(val){
          this.remarkList = val||[]
        }
      }
    }
  },
  mounted() {
    this.network().getInternalMarkList()
  },
  methods:{
    handlerEdit(){
      this.isEdit = true
    },
    handlerAddRemark(){
      this.$refs.form.validate((valid,rules) => {
        if (valid) {
          this.network().addInternalMark()
        }else {
          this.scrollToValidDom(rules)
        }
      })
    },
    network(){
      return{
        getInternalMarkList:async (params)=>{
          params = {
            ...params,
            order_no:this.orderNo
          }
          if(!params.order_no) return
          let {data} = await getInternalMarkList(params)
          this.remarkList = data
        },
        addInternalMark:async (params)=>{
          params = {
            ...this.formData,
            order_no:this.orderNo
          }
          if(!params.order_no) return
          let {data} = await addInternalMark(params)
          this.formData.remark = ''
          this.isEdit = false
          this.network().getInternalMarkList()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card{
}
.remarks-container{
  &.pd-20{
    //padding: 20px;
    padding-bottom: 20px;
  }
  &::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height:12px; // 纵向滚动条 必写
    display: block;
    background: #CBD5E0;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #718096;
    border-radius: 3px;
    cursor: pointer;
  }
}
.remark-box{
  //margin-bottom: 16px;
  padding: 20px 20px 0 20px;
  &:last-child{
    margin-bottom: 0;
  }
  .remark-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    span{
      color: #2D3748;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      line-height: 150%;
      &:nth-child(1){
        font-weight: 500;
      }
    }
  }
  p{
    color: #718096;
    /* Medium/Small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}
.remark-add{
  &.has-border{
    //border-top: 1px solid  #DCDFE6;
  }
  .el-form-item{
    margin-bottom: 15px;
  }
  padding: 20px;
}
</style>
