<template>
  <section>
    <policy-detail-info :form="formData"></policy-detail-info>
    <policy-holder-detail-info :form="formData"></policy-holder-detail-info>
    <div v-if="formData.class_id!==1004||formData.class_id===1004&&coverage.cover_type==4">
      <driver-info :form="formData"></driver-info>
    </div>
    <div v-if="formData.class_id!==1004">
      <vehicle-detail-info :form="formData" ></vehicle-detail-info>
    </div>
    <div>
      <insure-info  :form="formData" ></insure-info>
    </div>
    <coverage-info :form="formData"></coverage-info>
    <excess-info :form="formData"></excess-info>
    <remark-info :form="formData"></remark-info>
    <signature
      :form="formData"></signature>
    <div
      ref="document"
    >
      <document-info
        v-show="!isPremium"
        :form="formData"></document-info>
    </div>
    <driver-document-info
      v-if="!isPremium"
      :form="formData"></driver-document-info>
    <referral-info :form="formData.referral"></referral-info>
  </section>
</template>

<script>
import policyDetailInfo from '@/views/components/info/policyDetailInfo'
import coverageInfo from '@/views/components/info/coverageInfo'
import policyHolderDetailInfo from '@/views/components/info/policyHolderDetailInfo'
import excessInfo from '@/views/components/info/excessInfo'
import vehicleDetailInfo from '@/views/components/info/vehicleDetailInfo'
import driverInfo from '@/views/components/info/driverInfo'
import remarkInfo from '@/views/components/info/remarkInfo'
import documentInfo from '@/views/components/info/documentInfo'
import referralInfo from '@/views/components/info/referralInfo'
import InsureInfo from '@/views/components/info/insurerInfo'
import signature from '@/views/components/info/signature'
import driverDocumentInfo from '@/views/components/info/driverDocumentInfo'
export default {
  name: 'motoInfo',
  components:{
    InsureInfo,
    policyDetailInfo,
    coverageInfo,
    policyHolderDetailInfo,
    excessInfo,
    vehicleDetailInfo,
    driverInfo,
    remarkInfo,
    documentInfo,
    referralInfo,
    signature,
    driverDocumentInfo
  },
  props:{
    formData:{
      type:Object,
      default:()=>({})
    },
    isPremium:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return{
      // formData:{
      //   payment:{}
      // },
    }
  },
  computed:{
    coverage(){
      return this.formData.coverage||{}
    }
  },
  methods:{
    scrollToSection() {
      this.$refs.document.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }
}
</script>

<style scoped>
  .scroll-content{
    min-height: 100px;
  }
</style>
