<template>
  <section>
    <div
      v-if="form.client_type==1"
      class="table" >
      <div class="th">
        <span>Policyholder Details</span>
      </div>
      <div
        class="tr">
        <span>Title</span>
        <span>{{getOptionName('titleOption',form.policy_holder_title)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Name</span>
        <span>{{form.policy_holder_name}}
        </span>
      </div>
      <div
        v-if="productId!=11&&productId!=13"
        class="tr">
        <span>Date of Birth</span>
        <span>{{showHkDate(form.policy_holder_birth)}}</span>
      </div>
      <div
        class="tr">
        <span>{{form.policy_holder_id_type==1?'HKID':'Passport'}}</span>
        <span>{{form.policy_holder_id_no}}</span>
      </div>
      <div
        class="tr">
        <span>Mobile Number</span>
        <span>{{form.policy_holder_mobile}}</span>
      </div>
      <div
        v-if="isShowOccauption"
        class="tr">
        <span>Occupation</span>
        <!--        policy_holder_occ_id-->
        <span v-if="form.policy_holder_occ_id==70">{{getOptionName('globalOptions.occ',form.policy_holder_occ_id)}} {{form.policy_holder_occ}}</span>
        <span v-else>{{getOptionName('globalOptions.occ',form.policy_holder_occ_id)}}</span>
      </div>
      <div
        v-if="productId==12"
        class="tr">
        <span>Policyholder is one of the Traveler?</span>
        <span>{{ getOptionName('yesNoOptions', form.policy_holder_is_traveler) }}</span>
      </div>
      <div
        class="tr">
        <span>Correspondence Address</span>
        <span>{{address}}
        </span>
      </div>
      <div
        class="tr">
        <span>Policyholder is one of the Named Driver?</span>
        <span v-if="form.policy_holder_is_driver">{{ getOptionName('yesNo2Options', form.policy_holder_is_driver) }}</span>
        <span v-else></span>
      </div>
      <div
        v-if="form.policy_holder_occ_id==17"
        class="tr">
        <span>Will the vehicle be used for food delivery purpose?</span>
        <span>{{getOptionName('yesNo2Options',vehicle.is_delivery_purpose)}}
        </span>
      </div>
    </div>
    <div
      v-else
      class="table">
      <div class="th">
        <span>Policyholder Details</span>
      </div>
      <div
        class="tr">
        <span>Company Name</span>
        <span>{{form.holder_company_name}}
        </span>
      </div>

      <div
        class="tr">
        <span>BR / CI Number</span>
        <span>{{form.holder_company_id_no}}</span>
      </div>
      <div
        class="tr">
        <span>Company Phone Number</span>
        <span>{{form.holder_company_phone}}</span>
      </div>
      <div
        class="tr">
        <span>Company Email</span>
        <span>{{form.holder_company_email}}</span>
      </div>
      <div
        class="tr">
        <span>Nature of Business</span>
        <span>{{getOptionName('globalOptions.industry',form.holder_company_nature_id)}} <span v-if="form.holder_company_nature_id==48">{{ form.holder_company_nature }}</span></span>
      </div>
      <div
        v-if="form.holder_company_nature_id==14"
        class="tr">
        <span>Will the vehicle be used for food delivery purpose?</span>
        <span>{{getOptionName('yesNo2Options',vehicle.is_delivery_purpose)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Contact Person Name</span>
        <span>{{form.holder_contact_name}}</span>
      </div>
      <div
        class="tr">
        <span>Mobile Number</span>
        <span>{{form.holder_contact_phone}}</span>
      </div>
      <div
        class="tr">
        <span>Correspondence Address</span>
        <span>{{address}}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'

export default {
  name: 'policyHolderDetailInfo',
  mixins: [MixinOptions],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    address() {
      const {correspondence_address} =this.form
      if(correspondence_address)
      {
        let arr = [
          correspondence_address.room?`ROOM ${correspondence_address.room} `:'',
          correspondence_address.floor?`${correspondence_address.floor}/F `:'',
          correspondence_address.block?`BLOCK ${correspondence_address.block}`:'',
          correspondence_address.building,
          correspondence_address.street,
          this.getAreaName('district', correspondence_address.district),
          this.getAreaName('area', correspondence_address.area),
          this.getAreaName('region', correspondence_address.region)
        ]
        return arr.filter(item => item).join(', ')
      }else  return ''
    },
    vehicle() {
      const {vehicle_detail} =this.form
      return vehicle_detail||{}
    },
    productId(){
      return this.form.product_id
    },
    isShowOccauption(){
      let a = [10,12,13,16,17]
      return a.includes(this.form.product_id)
    }
  }
}
</script>

<style scoped>

</style>
